import axios from 'axios';

export async function sendMessageToAI(message, hisId) {
    const accessToken = localStorage.getItem('accessToken');
    try {
        const response = await axios.post(
            'https://api.avishifo.uz/api/chat/to/gpt/',
            { 
                message,
                history_id: hisId
            },
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${accessToken}`
                }
            }
        );
        
        const data = response.data;
        if (data && data.bot_response) {
            return formatResponseToHTML(data.bot_response);
        } else {
            throw new Error('Invalid response structure');
        }
    } catch (error) {
        console.error('Error sending message to AI:', error);
        return 'Sorry, there was an error processing your request.';
    }
}

// export async function createNewHistory() {
//     const accessToken = localStorage.getItem('accessToken');
//     try {
//         const response = await axios.post(
//             'https://api.avishifo.uz/api/chat/create/',
//             {},
//             {
//                 headers: {
//                     'Content-Type': 'application/json',
//                     'Authorization': `Token ${accessToken}`
//                 }
//             }
//         );

//         const data = response.data;
//         if (data && data.history_id) {
//             return data.history_id;
//         } else {
//             throw new Error('Invalid response structure');
//         }
//     } catch (error) {
//         console.error('Error creating new history:', error);
//         return null;
//     }
// }

function formatResponseToHTML(response) {
    response = response.replace(/\n/g, '<br />');
    const listRegex = /^(\d+)\.\s/gm;
    if (listRegex.test(response)) {
        response = response.split('<br />').map(line => {
            if (listRegex.test(line)) {
                return `<li>${line.replace(listRegex, '')}</li>`;
            }
            return line;
        }).join('');
        response = `<ul>${response}</ul>`;
    }
    return response;
}
