import React, { useState, useEffect, useRef } from 'react';

const TypingEffect = ({ text, speed = 30, onFinish }) => {
    const [displayedText, setDisplayedText] = useState('');
    const [index, setIndex] = useState(0);
    const typingEffectRef = useRef(null);

    useEffect(() => {
        setDisplayedText('');
        setIndex(0);
    }, [text]);

    useEffect(() => {
        if (index < text.length) {
            const timeout = setTimeout(() => {
                setDisplayedText((prev) => prev + text.charAt(index));
                setIndex((prev) => prev + 1);
                if (typingEffectRef.current) {
                    typingEffectRef.current.scrollIntoView({ behavior: "smooth" });
                }
            }, speed);
            return () => clearTimeout(timeout);
        } else {
            if (onFinish) {
                onFinish();
            }
        }
    }, [index, text, speed, onFinish]);

    return (
        <div ref={typingEffectRef} dangerouslySetInnerHTML={{ __html: displayedText }} />
    );
};

export default TypingEffect;
